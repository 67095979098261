import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    fontFamily: "PoppinsRegular",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderColor: "#B8B8B8",
    width: "90%",
    margin: "0 auto",
    paddingVertical: "10px",
    marginTop: "10px",
    marginBottom: "5px",
  },
  mainSection: {
    width: "90%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  sectionTitle: {
    fontSize: "11px",
    width: "100%",
    fontFamily: "PoppinsBold",
    fontWeight: "bold",
    color: "#457DFE",
    marginVertical: "10px",
  },
  personalInfo: {
    paddingVertical: "5px",
  },
  name: {
    fontSize: "25px",
    fontFamily: "PoppinsRegular",
  },
  role: {
    fontSize: "12px",
  },
  infoText: {
    fontSize: "8px",
  },
  contactInfo: {
    borderLeftWidth: 1,
    borderColor: "#B8B8B8",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-evenly",
    paddingLeft: "10px",
  },
  infoItem: {
    display: "flex",
    flexDirection: "row",
  },
  workSectionContainer: {
    width: "100%",
  },
  workInfoLeft: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    height: "550px",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  workInfoRight: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "550px",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  profilePic: {
    width: "100px",
    objectFit: "contain",
    marginRight: "30px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  biographySection: {
    borderBottomWidth: 1,
    borderColor: "#B8B8B8",
    width: "90%",
    margin: "0 auto",
    paddingBottom: "10px",
    marginBottom: "10px",
  },
  biographyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "30px",
  },
  biographyText: {
    fontSize: "8px",
    color: "#7f7f7f",
    width: "100%",
  },
  icon: {
    width: "10px",
    objectFit: "contain",
    height: "auto",
    marginLeft: "10px",
  },
  lineDot: {
    width: "15px",
  },
  timelineNode: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  timelineTitle: {
    fontSize: "8px",
    fontFamily: "PoppinsBold",
    marginLeft: "20px",
  },
  timelineDescription: {
    fontSize: "8px",
    marginRight: "5px",
  },
  nonAssessableSkill: {
    width: "45%",
    fontSize: "8px",
    marginRight: "5px",
    marginBottom: "5px",
  },
  timelineSubDescription: {
    fontSize: "8px",
    marginRight: "5px",
    width: "80%",
    color: "#6f6f6f",
  },
  timelineContainer: {
    borderLeftWidth: "2px",
    borderColor: "#FECC73",
    marginLeft: "6px",
    paddingLeft: "26px",
    paddingVertical: "10px",
  },
  nonAssessableSkillContainer: {
    borderLeftWidth: "2px",
    borderColor: "#FECC73",
    marginLeft: "6px",
    paddingLeft: "26px",
    paddingVertical: "10px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "80%",
  },
  timelineItem: {
    marginBottom: "3px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "80%",
  },
  rate: {
    display: "flex",
    flexDirection: "row",
  },
  rateDot: {
    width: "8px",
    height: "8px",
  },
  languagesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderLeftWidth: "2px",
    borderColor: "#FECC73",
    width: "50%",
  },
  chart: {
    width: "70px",
    marginHorizontal: "5px",
  },
  logo: {
    width: "70px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
});
