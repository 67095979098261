import styled from "styled-components";

export const EducationContainer = styled.div<{ opened: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  height: ${(props) => (props.opened ? "11em" : "0")};
  transition: all 200ms ease-in-out;

  .input-group {
    display: flex;
    flex-direction: column;
    width: 40%;
  }

  .input-container {
    width: 100%;
  }

  .input-item {
    width: 75%;
  }

  .textarea-container {
    width: 100%;
    align-items: flex-start;
  }

  button {
    margin-bottom: 1em;
  }
`;

export const EducationListContainer = styled.div`
  margin-top: 2em;

  .timeline-item-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
  }

  .info-container {
    width: 20em;
  }

  .period-title {
    font-weight: bold;
    margin: 0;
  }

  .delete-icon {
    color: #c9c9c9;
    margin-left: 1em;

    &:hover {
      color: #ff3f3f;
    }
  }

  .description {
    font-size: smaller;
    color: #5c5c5c;
  }

  .ant-timeline-item-tail {
    border-color: #faddaa;
  }
`;
