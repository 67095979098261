import { Button, Input, Rate, Select } from "antd";
import { FC, useState } from "react";
import { InputContainer } from "../../../../styles";
import { IPersonality, IProfSkill, SkillType } from "../../../../types";
import { ReactComponent as CircleFilled } from "../../../../assets/circle_filled.svg";
import { SkillsContainer } from "./styles";

const { Option } = Select;

interface SkillsProps {
  isOpen: boolean;
  skillTypes: SkillType[];
  onSelect?: (skill: IProfSkill | IPersonality) => void;
}

const AddSkill: FC<SkillsProps> = ({ isOpen, skillTypes, onSelect }) => {
  const [skill, setSkill] = useState<IProfSkill | IPersonality>({
    level: undefined,
    name: undefined,
    type: undefined,
  });

  const handleSelect = () => {
    if (onSelect !== undefined) onSelect(skill);

    setSkill({ level: undefined, name: undefined, type: undefined });
  };

  return (
    <SkillsContainer opened={isOpen}>
      <div className="input-group">
        <InputContainer className="input-container">
          <p>Type:</p>
          <Select
            placeholder="Select a skill"
            className="input-item"
            onSelect={(type: string) => setSkill({ ...skill, type })}
            value={skill.type}
          >
            {skillTypes.map((skillType, key) => (
              <Option key={key} value={skillType.name}>
                {skillType.name}
              </Option>
            ))}
          </Select>
        </InputContainer>
        <InputContainer className="input-container">
          <p>Skill name:</p>
          <Input
            className="input-item"
            value={skill.name}
            onChange={(event) =>
              setSkill({
                ...skill,
                name:
                  event.target.value !== "" ? event.target.value : undefined,
              })
            }
          />
        </InputContainer>
      </div>
      <InputContainer>
        <p>Level:</p>
        <Rate
          className="rate-input"
          count={10}
          character={() => <CircleFilled />}
          value={skill.level || 0}
          onChange={(value) =>
            setSkill({ ...skill, level: value !== 0 ? value : undefined })
          }
          disabled={
            !skillTypes.find((skillType) => skillType.name === skill.type)
              ?.assessable
          }
        />
      </InputContainer>
      <Button
        className="confirm-button"
        onClick={handleSelect}
        // disabled={Object.values(skill).indexOf(undefined) !== -1}
      >
        OK
      </Button>
    </SkillsContainer>
  );
};

export default AddSkill;
