import { DeleteOutlined } from "@ant-design/icons";
import { Button, Timeline } from "antd";
import { FC } from "react";
import { ReactComponent as CircleYellow } from "../../../../assets/circle_yellow.svg";
import { IEducation } from "../../../../types";
import { EducationListContainer } from "./styles";

interface EducationListProps {
  data: IEducation[];
  onDelete?: (key: number) => void;
}

const EducationList: FC<EducationListProps> = ({ data, onDelete }) => {
  const handleDelete = (key: number) => {
    if (onDelete) onDelete(key);
  };

  return (
    <EducationListContainer>
      <Timeline>
        {data.map((education, key) => (
          <Timeline.Item key={key} dot={<CircleYellow />}>
            <div className="timeline-item-container">
              <div className="info-container">
                <p className="period-title">{education.period}</p>
                <span>
                  {education.title} ({education.institute})
                </span>
                <p className="description">{education.description}</p>
              </div>
              <Button
                type="text"
                className="delete-icon"
                onClick={() => handleDelete(data.indexOf(education))}
                icon={<DeleteOutlined />}
              />
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </EducationListContainer>
  );
};

export default EducationList;
