import styled from "styled-components";

export const SkillsContainer = styled.div<{ opened: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  height: ${(props) => (props.opened ? "8em" : "0")};
  transition: all 200ms ease-in-out;

  .input-group {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    width: 100%;
  }

  .input-item {
    width: 75%;
  }

  .confirm-button {
    margin-bottom: 1em;
  }

  .rate-input {
    color: #6b6b6b;
  }
`;

export const SkillsListContainer = styled.div`
  margin-top: 2em;

  .skill-name {
    width: 10em;
  }

  .delete-icon {
    color: #c9c9c9;
    margin-left: 2em;

    &:hover {
      color: #ff3f3f;
    }
  }

  .ant-timeline-item-tail {
    border-color: #faddaa;
  }

  .timeline-item {
    width: 55%;
  }

  .timeline-item svg {
    width: 13px;
  }

  .rate-container {
    color: #6b6b6b;
    height: 1em;
    line-height: 1em;
    margin-left: 4em;
  }

  .skill-rate-container {
    display: flex;
    align-items: center;
    margin: 1em 0 0 1em;
  }
`;
