import { Button, Input } from "antd";
import { FC, useState } from "react";
import { InputContainer } from "../../../../styles";
import { IEducation } from "../../../../types";
import { EducationContainer } from "./styles";

interface AddEducationProps {
  isOpen: boolean;
  onAdd?: (education: IEducation) => void;
}

const AddEducation: FC<AddEducationProps> = ({ isOpen, onAdd }) => {
  const [education, setEducation] = useState<IEducation>({
    institute: undefined,
    title: undefined,
    period: undefined,
    description: undefined,
  });

  const handleAdd = () => {
    if (onAdd) onAdd(education);

    setEducation({
      institute: undefined,
      title: undefined,
      period: undefined,
      description: undefined,
    });
  };

  return (
    <EducationContainer opened={isOpen}>
      <div className="input-group">
        <InputContainer className="input-container">
          <p>Institute:</p>
          <Input
            className="input-item"
            value={education.institute}
            onChange={(event) => {
              setEducation({
                ...education,
                institute:
                  event.target.value === "" ? undefined : event.target.value,
              });
            }}
          />
        </InputContainer>

        <InputContainer className="input-container">
          <p>Title:</p>
          <Input
            className="input-item"
            value={education.title}
            onChange={(event) => {
              setEducation({
                ...education,
                title:
                  event.target.value === "" ? undefined : event.target.value,
              });
            }}
          />
        </InputContainer>

        <InputContainer className="input-container">
          <p>Period:</p>
          <Input
            className="input-item"
            value={education.period}
            onChange={(event) => {
              setEducation({
                ...education,
                period:
                  event.target.value === "" ? undefined : event.target.value,
              });
            }}
          />
        </InputContainer>
      </div>
      <div className="input-group">
        <InputContainer className="textarea-container">
          <p>Description: </p>
          <Input.TextArea
            placeholder="(optional)"
            rows={5}
            value={education.description}
            onChange={(event) => {
              setEducation({
                ...education,
                description:
                  event.target.value === "" ? undefined : event.target.value,
              });
            }}
          />
        </InputContainer>
      </div>
      <Button
        onClick={handleAdd}
        disabled={[0, 1, 2].includes(
          Object.values(education).indexOf(undefined)
        )}
      >
        OK
      </Button>
    </EducationContainer>
  );
};

export default AddEducation;
