import { Font } from "@react-pdf/renderer";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PoppinsBold from "./assets/fonts/Poppins-Bold.ttf";
import PoppinsRegular from "./assets/fonts/Poppins-Regular.ttf";
import ResumeBuilder from "./screens/ResumeBuilder";
import ResumeList from "./screens/ResumeList";

Font.register({
  family: "PoppinsRegular",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    {
      src: PoppinsRegular,
    },
  ],
});

Font.register({
  family: "PoppinsBold",
  fontStyle: "normal",
  fontWeight: "bold",
  fonts: [
    {
      src: PoppinsBold,
    },
  ],
});

let fontsLoaded = false;
let forceUpdate: (() => void) | null = null;

Promise.all([
  Font.load({ fontFamily: "PoppinsRegular" }),
  Font.load({ fontFamily: "PoppinsBold" }),
]).then(() => {
  fontsLoaded = true;
  if (forceUpdate) forceUpdate();
});

function useForceUpdate() {
  const [_value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const App = () => {
  forceUpdate = useForceUpdate();
  if (!fontsLoaded) {
    return <div />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/builder" element={<ResumeBuilder />} />
        <Route path="/" element={<ResumeList />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
