import styled from "styled-components";

export const Main = styled.div`
  font-family: "Poppins", sans-serif;
`;

export const Header = styled.div`
  max-width: 40em;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 4em;

  h1 {
    margin: 0;
  }
`;

export const ListElement = styled.div`
  /* background-color: red; */
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 40em;
  width: 80%;
  margin: 1em auto;
  padding: 1em;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  img {
    background-color: gray;
    width: 80px;
    height: 80px;
    border-radius: 5px;
  }

  h3 {
    font-weight: bold;
  }

  span {
    color: gray;
  }

  .resume-list-info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2em;
    padding-right: 1em;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;

  span {
    color: #cccccc;
  }
`;
