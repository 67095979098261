import { SkillType } from "./types";

export const professionalSkillsData: SkillType[] = [
  {
    name: "Programming Languages",
    assessable: true,
  },
  {
    name: "Markup Skills",
    assessable: true,
  },
  {
    name: "Frameworks & Libs",
    assessable: true,
  },
  {
    name: "Databases",
    assessable: false,
  },
  {
    name: "HTTP Apis",
    assessable: false,
  },
  {
    name: "Tools & resources",
    assessable: false,
  },
];

export const personalitySkillsData: SkillType[] = [
  {
    name: "Key Skills Qualification",
    assessable: true,
  },
  {
    name: "Aptitudes",
    assessable: true,
  },
];
