import { InboxOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import Dragger from "antd/lib/upload/Dragger";
import { FC, useEffect, useState } from "react";

interface UploadImageProps {
  defaultValue?: string;
  onSelect?: (img: string) => void;
}

const UploadImage: FC<UploadImageProps> = ({ onSelect, defaultValue }) => {
  const [imgFile, setImgFile] = useState<UploadFile>();

  useEffect(() => {
    console.log("DEFAULT", defaultValue);
    if (defaultValue) {
      const image: UploadFile = {
        uid: "",
        name: "bla",
        url: defaultValue,
      };

      setImgFile(image);
    }
  }, []);

  const imageResolver = (file: UploadFile<any>): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  };

  const onChange: UploadProps["onChange"] = async ({ file: newFile }) => {
    newFile.status = "done";
    setImgFile(newFile);

    let src = newFile.url as string;

    if (!src) src = await imageResolver(newFile);

    if (onSelect) {
      onSelect(src);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;

    if (!src) src = await imageResolver(file);

    const image = new Image();
    image.src = src;

    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <ImgCrop rotate>
      <Dragger
        className="drag-image"
        listType="picture-card"
        maxCount={1}
        onPreview={onPreview}
        onChange={onChange}
        accept="image/*"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined size={15} />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
    </ImgCrop>
  );
};

export default UploadImage;
