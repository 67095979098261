import { Button, message, Popconfirm, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { IResume } from "../../types";
import { Header, ListElement, Loading, Main, NoData } from "./styles";
import { useNavigate } from "react-router-dom";
import DefaultProfilePicture from "../../assets/default-user.jpg";

const ResumeList = () => {
  const [resumes, setResumes] = useState<IResume[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await fetch(
      "https://5rewmh06l3.execute-api.us-east-1.amazonaws.com/dev/resumaker",
      {
        method: "GET",
      }
    );

    if (data.status === 200) {
      const resumes: IResume[] = await data.json();
      setResumes(resumes);
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    setFetchLoading(true);

    fetchData().catch((err) => {
      setFetchLoading(false);
      message.error({
        content: "Something went wrong!",
        duration: 2,
      });
    });
  }, []);

  const handleDelete = async (id: string | undefined) => {
    if (id) {
      message.loading({ content: "Deleting resume...", key: "delete-resume" });
      const data = await fetch(
        `https://5rewmh06l3.execute-api.us-east-1.amazonaws.com/dev/resumaker/${id}`,
        {
          method: "DELETE",
        }
      );

      if (data.status === 200) {
        message.success({
          content: "Resume deleted successfully!",
          key: "delete-resume",
          duration: 2,
        });
        fetchData();
      } else {
        message.error({
          content: "Something went wrong!",
          key: "delete-resume",
          duration: 2,
        });
      }
    }
  };

  return (
    <Main>
      <Header>
        <h1>resumaker</h1>
        <Button
          type="primary"
          onClick={() => {
            navigate("/builder");
          }}
        >
          Create new resume
        </Button>
      </Header>
      <div>
        {fetchLoading ? (
          <Loading>
            <Spin />
          </Loading>
        ) : (
          <>
            {resumes.length === 0 ? (
              <NoData>
                <span>No data</span>
              </NoData>
            ) : (
              resumes.map((resume) => (
                <ListElement>
                  <div className="img-container">
                    <img
                      src={
                        resume.personalInfo.profilePicture ||
                        DefaultProfilePicture
                      }
                      alt=""
                    />
                  </div>
                  <div className="resume-list-info">
                    <div>
                      <h3>{resume.personalInfo.name || ""}</h3>
                      <span>{resume.personalInfo.role || ""}</span>
                    </div>
                    <div className="actions">
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => {
                          navigate("/builder", { state: { resume } });
                        }}
                      />
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDelete(resume.id)}
                      >
                        <Button type="text" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </div>
                  </div>
                </ListElement>
              ))
            )}
          </>
        )}
      </div>
    </Main>
  );
};

export default ResumeList;
