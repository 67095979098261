import { Image, View } from "@react-pdf/renderer";
import { FC } from "react";
import CircleFilledBlack from "../../../../../assets/circle_filled_black.png";
import CircleFilledGray from "../../../../../assets/circle_filled_gray.png";
import { styles } from "../styles";

interface RateProps {
  value: number | undefined;
}

const Rate: FC<RateProps> = ({ value }) => {
  return (
    <View style={styles.rate}>
      {[...Array(10)].map((elem, key) => {
        return (
          <Image
            src={value && key < value ? CircleFilledBlack : CircleFilledGray}
            style={styles.rateDot}
          />
        );
      })}
    </View>
  );
};

export default Rate;
