import { Image, Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import { IPersonality, IProfSkill, SkillType } from "../../../../../types";
import CircleYellow from "../../../../../assets/circle_yellow.png";
import { styles } from "../styles";
import Rate from "./Rate";

interface SkillsListProps {
  data: IProfSkill[] | IPersonality[];
  skillTypesData: SkillType[];
}

const PDFSkillsList: FC<SkillsListProps> = ({ data, skillTypesData }) => {
  const skills = (skillType: string) => {
    return data.filter((profSkill) => profSkill.type === skillType);
  };

  return (
    <View style={styles.workSectionContainer}>
      {skillTypesData.map(
        (skillType, skillKey) =>
          skills(skillType.name).length !== 0 && (
            <>
              <View style={styles.timelineNode}>
                <Image src={CircleYellow} style={styles.lineDot} />
                <Text style={styles.timelineTitle}>{skillType.name}</Text>
              </View>

              {skillType.assessable ? (
                <View style={styles.timelineContainer}>
                  {skills(skillType.name).map((sk, key) => (
                    <View style={styles.timelineItem} key={key}>
                      <Text style={styles.timelineDescription}>{sk.name}</Text>
                      {skillType.assessable && <Rate value={sk.level} />}
                    </View>
                  ))}
                </View>
              ) : (
                <View style={styles.nonAssessableSkillContainer}>
                  {skills(skillType.name).map((sk, key) => (
                    <Text style={styles.nonAssessableSkill} key={key}>
                      {sk.name}
                    </Text>
                  ))}
                </View>
              )}
            </>
          )
      )}
    </View>
  );
};

export default PDFSkillsList;
