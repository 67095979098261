import styled from "styled-components";

export const PersonalInfoContainer = styled.div`
  .input-group {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .personal-input {
    width: 70%;
  }
`;

export const ProfilePic = styled.div`
  margin-top: 1em;

  p {
    margin: 0;
  }

  .drag-image {
    margin-bottom: 2em;
  }
`;
export const Bio = styled.div`
  margin-top: 2em;

  p {
    margin: 0;
  }
`;
