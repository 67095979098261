import { DeleteOutlined } from "@ant-design/icons";
import { Button, Rate, Timeline } from "antd";
import { FC } from "react";
import { ReactComponent as CircleFilled } from "../../../../assets/circle_filled.svg";
import { ReactComponent as CircleYellow } from "../../../../assets/circle_yellow.svg";
import { IProfSkill, SkillType } from "../../../../types";
import { SkillsListContainer } from "./styles";

interface SkillsListProps {
  data: IProfSkill[];
  skillTypesData: SkillType[];
  onDelete?: (key: number) => void;
}

const SkillsList: FC<SkillsListProps> = ({
  data,
  onDelete,
  skillTypesData,
}) => {
  const handleDelete = (key: number) => {
    if (onDelete) onDelete(key);
  };

  const skills = (skillType: string) => {
    return data.filter((profSkill) => profSkill.type === skillType);
  };

  return (
    <SkillsListContainer>
      <Timeline>
        {skillTypesData.map((skillType, skillKey) => {
          return (
            skills(skillType.name).length !== 0 && (
              <Timeline.Item
                key={skillKey}
                dot={<CircleYellow />}
                className="timeline-item"
              >
                <h4>{skillType.name}</h4>
                {skills(skillType.name).map((sk, key) => (
                  <div key={key} className="skill-rate-container">
                    <span className="skill-name">{sk.name}</span>
                    {skillType.assessable && (
                      <Rate
                        className="rate-container"
                        disabled
                        count={10}
                        character={() => <CircleFilled />}
                        value={sk.level}
                      />
                    )}
                    <Button
                      type="text"
                      className="delete-icon"
                      onClick={() => handleDelete(data.indexOf(sk))}
                      icon={<DeleteOutlined />}
                    />
                  </div>
                ))}
              </Timeline.Item>
            )
          );
        })}
      </Timeline>
    </SkillsListContainer>
  );
};

export default SkillsList;
