import { DeleteOutlined } from "@ant-design/icons";
import { Button, Timeline } from "antd";
import { FC } from "react";
import { ReactComponent as CircleYellow } from "../../../../assets/circle_yellow.svg";
import { IExperience } from "../../../../types";
import { ExperienceListContainer } from "./styles";

interface ExperienceListProps {
  data: IExperience[];
  onDelete?: (key: number) => void;
}

const ExperienceList: FC<ExperienceListProps> = ({ data, onDelete }) => {
  const handleDelete = (key: number) => {
    if (onDelete) onDelete(key);
  };
  return (
    <ExperienceListContainer>
      <Timeline>
        {data.map((experience, key) => (
          <Timeline.Item key={key} dot={<CircleYellow />}>
            <div className="timeline-item-container">
              <div className="info-container">
                <p className="period-title">{experience.period}</p>

                <span className="">
                  {experience.title} ({experience.companyName})
                </span>
              </div>
              <Button
                type="text"
                className="delete-icon"
                onClick={() => handleDelete(data.indexOf(experience))}
                icon={<DeleteOutlined />}
              />
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </ExperienceListContainer>
  );
};

export default ExperienceList;
