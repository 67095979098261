import styled from "styled-components";

export const LanguagesContainer = styled.div<{ opened: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: ${(props) => (props.opened ? "8em" : "0")};
  transition: all 200ms ease-in-out;

  button {
    margin-bottom: 1em;
  }

  .input-item {
    width: auto;
  }
`;

export const LanguagesListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .list-item {
    display: flex;
    margin-right: 2em;
  }

  .delete-icon {
    color: #c9c9c9;

    &:hover {
      color: #ff3f3f;
    }
  }
`;
