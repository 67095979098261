import { Input } from "antd";
import { FC, useEffect, useState } from "react";
import { InputContainer } from "../../../../styles";
import { IPersonalInfo } from "../../../../types";
import UploadImage from "../uploadImage/UploadImage";
import { Bio, PersonalInfoContainer, ProfilePic } from "./styles";

interface PersonalInfoProps {
  initialValue?: IPersonalInfo;
  onChange?: (personalInfo: IPersonalInfo) => void;
}

const PersonalInfo: FC<PersonalInfoProps> = ({ onChange, initialValue }) => {
  const [personalInfo, setPersonalInfo] = useState<IPersonalInfo>(
    initialValue || {
      name: undefined,
      role: undefined,
      location: undefined,
      email: undefined,
      website: undefined,
      github: undefined,
      profilePicture: undefined,
      bio: undefined,
    }
  );

  const handleChange = (index: string, value: string) => {
    setPersonalInfo({
      ...personalInfo,
      [index]: value !== "" ? value : undefined,
    });
  };

  useEffect(() => {
    if (onChange) onChange(personalInfo);
  }, [personalInfo]);

  return (
    <PersonalInfoContainer>
      <div className="input-group">
        <InputContainer>
          <p>Name: </p>
          <Input
            className="personal-input"
            defaultValue={personalInfo.name || ""}
            onChange={(event) => handleChange("name", event.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Role: </p>
          <Input
            className="personal-input"
            defaultValue={personalInfo.role}
            onChange={(event) => handleChange("role", event.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Location: </p>
          <Input
            className="personal-input"
            defaultValue={personalInfo.location}
            onChange={(event) => handleChange("location", event.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Website: </p>
          <Input
            className="personal-input"
            defaultValue={personalInfo.website}
            onChange={(event) => handleChange("website", event.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Email: </p>
          <Input
            className="personal-input"
            defaultValue={personalInfo.email}
            onChange={(event) => handleChange("email", event.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Github: </p>
          <Input
            className="personal-input"
            defaultValue={personalInfo.github}
            onChange={(event) => handleChange("github", event.target.value)}
          />
        </InputContainer>
      </div>
      <ProfilePic>
        <p>Profile picture: </p>
        <UploadImage
          defaultValue={personalInfo.profilePicture}
          onSelect={(imgUrlData) => handleChange("profilePicture", imgUrlData)}
        />
      </ProfilePic>

      <Bio>
        <p>Bio: </p>
        <Input.TextArea
          onChange={(event) => handleChange("bio", event.target.value)}
        />
      </Bio>
    </PersonalInfoContainer>
  );
};

export default PersonalInfo;
