import styled from "styled-components";
import { blue } from "@ant-design/colors";

export const Main = styled.div`
  width: 65%;
  padding-bottom: 5rem;
  margin: auto;
  font-family: "Poppins", sans-serif;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const InputContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;

  .aligned-input {
    width: 75%;
  }

  p {
    margin: 0 1em 0 0;
  }
`;

export const SectionTitle = styled.div`
  margin: 4em 0 2em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    color: ${blue.primary};
  }
`;
