import { ApexOptions } from "apexcharts";
import { FC } from "react";
import Chart from "react-apexcharts";

interface LanguageChartProps {
  name: string;
  proficiency: string;
  percentage: number;
}

const LanguageChart: FC<LanguageChartProps> = ({
  name,
  percentage,
  proficiency,
}) => {
  const chartOptions: ApexOptions = {
    labels: [name],
    series: [+percentage],
    chart: {
      id: "pdfchart",
      type: "radialBar",
      height: 220,
      width: 180,
      animations: {
        enabled: false,
      },
    },
    stroke: {
      lineCap: "round",
    },
    fill: {
      type: "solid",
      colors: ["#5f5f5f"],
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        dataLabels: {
          value: {
            offsetY: -1,
            color: "#111",
            fontSize: "0.7em",
            formatter: () => proficiency,
          },
          name: {
            offsetY: -5,
            show: true,
            color: "#111",
            fontSize: "1.2em",
          },
        },
        hollow: {
          margin: 15,
          size: "75%",
        },
      },
    },
  };

  return (
    <>
      <div>
        <Chart
          options={chartOptions}
          height={220}
          width={180}
          series={[+percentage]}
          type="radialBar"
        />
      </div>
    </>
  );
};

export default LanguageChart;
