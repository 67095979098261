import { Button, Input } from "antd";
import { FC, useState } from "react";
import { InputContainer } from "../../../../styles";
import { IExperience } from "../../../../types";
import { ExperienceContainer } from "./styles";

interface ExperienceProps {
  isOpen: boolean;
  onAdd?: (experience: IExperience) => void;
}

const AddExperience: FC<ExperienceProps> = ({ isOpen, onAdd }) => {
  const [experience, setExperience] = useState<IExperience>({
    title: undefined,
    companyName: undefined,
    period: undefined,
  });

  const handleAdd = () => {
    if (onAdd) onAdd(experience);

    setExperience({
      title: undefined,
      companyName: undefined,
      period: undefined,
    });
  };

  return (
    <ExperienceContainer opened={isOpen}>
      <div className="input-group">
        <InputContainer className="full-width">
          <p>Period:</p>
          <Input
            className="aligned-input"
            value={experience.period}
            onChange={(element) =>
              setExperience({
                ...experience,
                period:
                  element.target.value !== ""
                    ? element.target.value
                    : undefined,
              })
            }
          />
        </InputContainer>

        <InputContainer className="full-width">
          <p>Title:</p>
          <Input
            className="aligned-input"
            value={experience.title}
            onChange={(element) =>
              setExperience({
                ...experience,
                title:
                  element.target.value !== ""
                    ? element.target.value
                    : undefined,
              })
            }
          />
        </InputContainer>
      </div>

      <InputContainer>
        <p>Company name:</p>
        <Input
          value={experience.companyName}
          onChange={(element) => {
            setExperience({
              ...experience,
              companyName:
                element.target.value !== "" ? element.target.value : undefined,
            });
          }}
        />
      </InputContainer>
      <Button
        className="confirm-button"
        onClick={handleAdd}
        disabled={Object.values(experience).indexOf(undefined) !== -1}
      >
        OK
      </Button>
    </ExperienceContainer>
  );
};

export default AddExperience;
