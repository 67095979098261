import { Button, Input, Select } from "antd";
import { ApexOptions } from "apexcharts";
import { FC, useState } from "react";
import { InputContainer } from "../../../../styles";
import { ILanguage } from "../../../../types";
import { LanguagesContainer } from "./style";

const { Option } = Select;

interface AddLanguageProps {
  isOpen: boolean;
  onAdd?: (language: ILanguage) => void;
}

const AddLanguage: FC<AddLanguageProps> = ({ isOpen, onAdd }) => {
  const [language, setLanguage] = useState<ILanguage>({
    name: undefined,
    proficiency: undefined,
    percentage: undefined,
  });
  const chartOptions: ApexOptions = {
    labels: [language.name || ""],

    series: [language.percentage || 0],
    chart: {
      id: "pdfchart",
      type: "radialBar",
      height: 220,
      width: 180,
      animations: {
        enabled: false,
      },
    },
    stroke: {
      lineCap: "round",
    },
    fill: {
      type: "solid",
      colors: ["#5f5f5f"],
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        dataLabels: {
          value: {
            offsetY: -1,
            color: "#111",
            fontSize: "0.7em",
            formatter: () => language.proficiency || "",
          },
          name: {
            offsetY: -5,
            show: true,
            color: "#111",
            fontSize: "1.2em",
          },
        },
        hollow: {
          margin: 15,
          size: "75%",
        },
      },
    },
  };

  const handleAdd = () => {
    const container = document.getElementById("pdfchart");
    if (container) {
      var chart = new ApexCharts(container, chartOptions);

      chart.render();
      container.style.display = "none";

      chart.dataURI().then((uri: any) => {
        if (onAdd) onAdd({ ...language, chartImage: uri.imgURI });
        container.innerHTML = "";
      });
    }

    setLanguage({
      name: undefined,
      proficiency: undefined,
      percentage: undefined,
    });
  };

  return (
    <LanguagesContainer opened={isOpen}>
      <InputContainer>
        <p>Language:</p>
        <Input
          value={language.name}
          onChange={(event) =>
            setLanguage({
              ...language,
              name: event.target.value === "" ? undefined : event.target.value,
            })
          }
        />
      </InputContainer>

      <InputContainer className="input-item">
        <p>Proficiency:</p>
        <Select
          placeholder="Select a level:"
          value={language.proficiency}
          onChange={(value) =>
            setLanguage({
              ...language,
              proficiency: value === "" ? undefined : value,
            })
          }
        >
          <Option value="Basic/Medium">Basic/Medium</Option>
          <Option value="Advanced">Advanced</Option>
          <Option value="Native or bilingual proficiency">
            Native or bilingual proficiency
          </Option>
        </Select>
      </InputContainer>

      <InputContainer className="input-item">
        <p>Percentage:</p>
        <Input
          type="number"
          min={0}
          max={100}
          value={language.percentage}
          onChange={(event) =>
            setLanguage({
              ...language,
              percentage:
                event.target.value === "" ? undefined : +event.target.value,
            })
          }
        />
      </InputContainer>
      <Button
        onClick={handleAdd}
        disabled={
          (language.percentage && language.percentage > 100) ||
          Object.values(language).indexOf(undefined) !== -1
        }
      >
        OK
      </Button>
      <div id="pdfchart"></div>
    </LanguagesContainer>
  );
};

export default AddLanguage;
