import { DownloadOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button, Divider, Dropdown, Menu, message, Typography } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { personalitySkillsData, professionalSkillsData } from "../../helpers";
import { Header, Main, SectionTitle } from "../../styles";
import { IResume } from "../../types";
import AddEducation from "./components/education/AddEducation";
import EducationList from "./components/education/EducationList";
import AddExperience from "./components/experience/AddExperience";
import ExperienceList from "./components/experience/ExperienceList";
import AddLanguage from "./components/languages/AddLanguage";
import LanguaguesList from "./components/languages/LanguagesList";
import File from "./components/pdfgenerator/File";
import PersonalInfo from "./components/personal/PersonalInfo";
import AddSkill from "./components/skills/AddSkill";
import SkillsList from "./components/skills/SkillsList";

const { Title } = Typography;

type LocationState = {
  resume: IResume;
};

const initialState = {
  id: undefined,
  personalInfo: {},
  skills: [],
  experience: [],
  education: [],
  personality: [],
  languages: [],
};

const ResumeBuilder = () => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const resume = locationState ? locationState.resume : initialState;

  const [profSkillsOpened, setProfSkillsOpened] = useState<boolean>(false);
  const [experienceOpened, setExperienceOpened] = useState<boolean>(false);
  const [personalityOpened, setPersonalityOpened] = useState<boolean>(false);
  const [languagesOpened, setLanguagesOpened] = useState<boolean>(false);
  const [educationOpened, setEducationOpened] = useState<boolean>(false);

  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [resumeInfo, setResumeInfo] = useState<IResume>({
    id: resume.id,
    personalInfo: resume.personalInfo || {},
    skills: resume.skills || [],
    experience: resume.experience || [],
    education: resume.education || [],
    personality: resume.personality || [],
    languages: resume.languages || [],
  });

  const handleSubmit = async () => {
    setSaveLoading(true);
    const response = await fetch(
      "https://5rewmh06l3.execute-api.us-east-1.amazonaws.com/dev/resumaker",
      {
        method: "POST",
        body: JSON.stringify({
          ...resumeInfo,
        }),
      }
    );

    if (response.status === 200) {
      setResumeInfo(initialState);
      setSaveLoading(false);
      message.success({
        content: "Resume saved successfully!",
        duration: 2,
      });
    } else {
      setSaveLoading(false);
      message.error({
        content: "Something went wrong!",
        duration: 2,
      });
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          disabled: true,
          label: (
            <>
              <EyeOutlined /> Preview
            </>
          ),
        },
        {
          key: "2",
          label: (
            <PDFDownloadLink
              document={<File resumeInfo={resumeInfo} />}
              fileName="BROS-Resume.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button type="text">
                    <DownloadOutlined /> Save as PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
          ),
        },
      ]}
    />
  );

  return (
    <Main>
      <Header>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          loading={saveLoading}
        >
          Save
        </Button>
        <Dropdown overlay={menu} placement="bottomLeft">
          <Button
            style={{ marginLeft: "1em" }}
            type="text"
            icon={<MoreOutlined style={{ fontSize: "20px" }} />}
          />
        </Dropdown>
      </Header>

      <SectionTitle>
        <Title level={4}>Personal Information</Title>
      </SectionTitle>

      <PersonalInfo
        initialValue={resumeInfo.personalInfo}
        onChange={(personalInfo) =>
          setResumeInfo({ ...resumeInfo, personalInfo })
        }
      />
      <Divider />

      <SectionTitle>
        <Title level={4}>Professional Skills</Title>
        <Button
          type={profSkillsOpened ? "default" : "primary"}
          onClick={() => {
            setProfSkillsOpened(!profSkillsOpened);
          }}
        >
          {profSkillsOpened ? "Cancel" : "Add"}
        </Button>
      </SectionTitle>

      <AddSkill
        skillTypes={professionalSkillsData}
        isOpen={profSkillsOpened}
        onSelect={(skill) => {
          const { skills } = resumeInfo;
          skills.push(skill);
          setResumeInfo({ ...resumeInfo, skills });
        }}
      />

      <SkillsList
        data={resumeInfo.skills}
        skillTypesData={professionalSkillsData}
        onDelete={(key) => {
          const newSkills = resumeInfo.skills.filter(
            (skill, skillKey) => skillKey !== key
          );
          setResumeInfo({ ...resumeInfo, skills: newSkills });
        }}
      />

      <Divider />

      <SectionTitle>
        <Title level={4}>Experience</Title>
        <Button
          type={experienceOpened ? "default" : "primary"}
          onClick={() => {
            setExperienceOpened(!experienceOpened);
          }}
        >
          {experienceOpened ? "Cancel" : "Add"}
        </Button>
      </SectionTitle>

      <AddExperience
        isOpen={experienceOpened}
        onAdd={(exp) => {
          const { experience } = resumeInfo;
          experience.push(exp);
          setResumeInfo({ ...resumeInfo, experience });
        }}
      />

      <ExperienceList
        data={resumeInfo.experience}
        onDelete={(key) => {
          const newExperience = resumeInfo.experience.filter(
            (exp, experienceKey) => experienceKey !== key
          );
          setResumeInfo({ ...resumeInfo, experience: newExperience });
        }}
      />

      <Divider />

      <SectionTitle>
        <Title level={4}>Education</Title>
        <Button
          type={educationOpened ? "default" : "primary"}
          onClick={() => {
            setEducationOpened(!educationOpened);
          }}
        >
          {educationOpened ? "Cancel" : "Add"}
        </Button>
      </SectionTitle>

      <AddEducation
        isOpen={educationOpened}
        onAdd={(educ) => {
          const { education } = resumeInfo;
          education.push(educ);
          setResumeInfo({ ...resumeInfo, education });
        }}
      />

      <EducationList
        data={resumeInfo.education}
        onDelete={(key) => {
          const newEducation = resumeInfo.education.filter(
            (educ, educationKey) => educationKey !== key
          );

          setResumeInfo({ ...resumeInfo, education: newEducation });
        }}
      />

      <Divider />

      <SectionTitle>
        <Title level={4}>Personality</Title>
        <Button
          type={personalityOpened ? "default" : "primary"}
          onClick={() => {
            setPersonalityOpened(!personalityOpened);
          }}
        >
          {personalityOpened ? "Cancel" : "Add"}
        </Button>
      </SectionTitle>

      <AddSkill
        skillTypes={personalitySkillsData}
        isOpen={personalityOpened}
        onSelect={(skill) => {
          const { personality } = resumeInfo;
          personality.push(skill);
          setResumeInfo({ ...resumeInfo, personality });
        }}
      />

      <SkillsList
        data={resumeInfo.personality}
        skillTypesData={personalitySkillsData}
        onDelete={(key) => {
          const newSkills = resumeInfo.personality.filter(
            (skill, skillKey) => skillKey !== key
          );
          setResumeInfo({ ...resumeInfo, personality: newSkills });
        }}
      />

      <Divider />

      <SectionTitle>
        <Title level={4}>Languages</Title>
        <Button
          type={languagesOpened ? "default" : "primary"}
          onClick={() => {
            setLanguagesOpened(!languagesOpened);
          }}
        >
          {languagesOpened ? "Cancel" : "Add"}
        </Button>
      </SectionTitle>

      <AddLanguage
        isOpen={languagesOpened}
        onAdd={(language) => {
          const { languages } = resumeInfo;
          languages.push(language);
          setResumeInfo({ ...resumeInfo, languages });
        }}
      />

      <LanguaguesList
        data={resumeInfo.languages}
        onDelete={(key) => {
          const newLanguage = resumeInfo.languages.filter(
            (educ, languageKey) => languageKey !== key
          );

          setResumeInfo({ ...resumeInfo, languages: newLanguage });
        }}
      />
    </Main>
  );
};

export default ResumeBuilder;
