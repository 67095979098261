import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import CircleYellow from "../../../../assets/circle_yellow.png";
import IconEmail from "../../../../assets/icon_email.png";
import IconGithub from "../../../../assets/icon_github.png";
import IconMap from "../../../../assets/icon_map.png";
import IconWeb from "../../../../assets/icon_web.png";
import Logo from "../../../../assets/logo.png";
import {
  personalitySkillsData,
  professionalSkillsData,
} from "../../../../helpers";
import { IResume } from "../../../../types";
import PDFSkillsList from "./components/PDFSkillsList";
import { styles } from "./styles";

interface FileProps {
  resumeInfo: IResume;
}

const File: FC<FileProps> = ({ resumeInfo }) => {
  return (
    <Document>
      <Page style={styles.body} wrap={false}>
        <View style={styles.header}>
          <View style={styles.personalInfo}>
            <Text style={styles.name}>{resumeInfo.personalInfo.name}</Text>
            <Text style={styles.role}>{resumeInfo.personalInfo.role}</Text>
          </View>
          <View style={styles.contactInfo}>
            {resumeInfo.personalInfo.location && (
              <View style={styles.infoItem}>
                <Text style={styles.infoText}>
                  {resumeInfo.personalInfo.location}
                </Text>
                <Image src={IconMap} style={styles.icon} />
              </View>
            )}

            {resumeInfo.personalInfo.email && (
              <View style={styles.infoItem}>
                <Text style={styles.infoText}>
                  {resumeInfo.personalInfo.email}
                </Text>
                <Image src={IconEmail} style={styles.icon} />
              </View>
            )}

            {resumeInfo.personalInfo.website && (
              <View style={styles.infoItem}>
                <Text style={styles.infoText}>
                  {resumeInfo.personalInfo.website}
                </Text>
                <Image src={IconWeb} style={styles.icon} />
              </View>
            )}

            {resumeInfo.personalInfo.github && (
              <View style={styles.infoItem}>
                <Text style={styles.infoText}>
                  {resumeInfo.personalInfo.github}
                </Text>
                <Image src={IconGithub} style={styles.icon} />
              </View>
            )}
          </View>
        </View>

        {(resumeInfo.personalInfo.profilePicture !== undefined ||
          resumeInfo.personalInfo.bio !== undefined) && (
          <View style={styles.biographySection}>
            <Text style={styles.sectionTitle}>BIOGRAPHY</Text>
            <View style={styles.biographyContainer}>
              {resumeInfo.personalInfo.profilePicture && (
                <Image
                  src={resumeInfo.personalInfo.profilePicture}
                  style={styles.profilePic}
                />
              )}
              <Text style={styles.biographyText}>
                {resumeInfo.personalInfo.bio}
              </Text>
            </View>
          </View>
        )}

        <View style={styles.mainSection}>
          <View style={styles.workInfoLeft}>
            {resumeInfo.skills.length !== 0 && (
              <Text style={styles.sectionTitle}>PROFESSIONAL SKILLS</Text>
            )}
            <PDFSkillsList
              data={resumeInfo.skills}
              skillTypesData={professionalSkillsData}
            />
          </View>
          <View style={styles.workInfoRight}>
            {resumeInfo.experience.length !== 0 && (
              <Text style={styles.sectionTitle}>EXPERIENCE</Text>
            )}
            {resumeInfo.experience.map((exp) => (
              <View style={styles.workSectionContainer}>
                <View style={styles.timelineNode}>
                  <Image src={CircleYellow} style={styles.lineDot} />
                  <Text style={styles.timelineTitle}>{exp.period}</Text>
                </View>
                <View style={styles.timelineContainer}>
                  <View style={styles.timelineItem}>
                    <Text style={styles.timelineDescription}>
                      {exp.title} ({exp.companyName})
                    </Text>
                  </View>
                </View>
              </View>
            ))}

            {resumeInfo.personality.length !== 0 && (
              <Text style={styles.sectionTitle}>PERSONALITY</Text>
            )}
            <PDFSkillsList
              data={resumeInfo.personality}
              skillTypesData={personalitySkillsData}
            />

            {resumeInfo.education.length !== 0 && (
              <Text style={styles.sectionTitle}>EDUCATION</Text>
            )}
            {resumeInfo.education.map((educ) => (
              <View style={styles.workSectionContainer}>
                <View style={styles.timelineNode}>
                  <Image src={CircleYellow} style={styles.lineDot} />
                  <Text style={styles.timelineTitle}>{educ.period}</Text>
                </View>
                <View style={styles.timelineContainer}>
                  <View style={styles.timelineItem}>
                    <Text style={styles.timelineDescription}>
                      {educ.title} ({educ.institute})
                    </Text>
                  </View>
                  {educ.description && (
                    <Text style={styles.timelineSubDescription}>
                      {educ.description}
                    </Text>
                  )}
                </View>
              </View>
            ))}

            {resumeInfo.languages.length !== 0 && (
              <Text style={styles.sectionTitle}>LANGUAGES</Text>
            )}
            <View style={styles.languagesContainer}>
              {resumeInfo.languages.map(
                (language, key) =>
                  language.chartImage && (
                    <Image
                      key={key}
                      src={language.chartImage}
                      style={styles.chart}
                    />
                  )
              )}
            </View>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Image style={styles.logo} src={Logo} />
        </View>
      </Page>
    </Document>
  );
};

export default File;
