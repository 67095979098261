import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FC } from "react";
import { ILanguage } from "../../../../types";
import LanguageChart from "./LanguageChart";
import { LanguagesListContainer } from "./style";

interface LanguagesListProps {
  data: ILanguage[];
  onDelete?: (key: number) => void;
}

const LanguaguesList: FC<LanguagesListProps> = ({ data, onDelete }) => {
  const handleDelete = (key: number) => {
    if (onDelete) onDelete(key);
  };

  return (
    <LanguagesListContainer>
      {data.map((language, key) => (
        <div key={key} className="list-item">
          <LanguageChart
            name={language.name || ""}
            percentage={language.percentage || 0}
            proficiency={language.proficiency || ""}
          />
          <Button
            type="text"
            className="delete-icon"
            onClick={() => {}}
            icon={
              <DeleteOutlined
                onClick={() => handleDelete(data.indexOf(language))}
              />
            }
          />
        </div>
      ))}
    </LanguagesListContainer>
  );
};

export default LanguaguesList;
